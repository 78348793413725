export const APP_VERSION = "1.0.2";

export const DOTS = "..";
export const ADD = "add";
export const REMOVE = "remove";
export const ADD_ALL = "add_all";
export const REMOVE_ALL = "remove_all";
export const NEXT = "next";
export const PREV = "prev";

export const POSITIVE = "Positive";
export const NEGATIVE = "Negative";

export const POLARITY = ["Positive", "Negative"] as const;

export const PROGRESSION = ["Up", "Down", "Same"] as const;

export enum Lv1HealthFactor {
  Competition = "Competition",
  ESG = "ESG",
  "Earnings Power" = "Earnings Power",
  Management = "Management",
  Reputation = "Reputation",
  Systemic = "Systemic",
}

export const TUTORIAL_VIDEOS: {
  [id: string]: {
    title: string;
    url: string;
  };
} = {
  healthFactors: {
    title: "Health Factors",
    url: "https://www.youtube.com/watch?v=PsYWYhVlJ8s",
  },
  homepage: {
    title: "Homepage",
    url: "https://www.youtube.com/watch?v=csQ4G1o6DD4",
  },
  orientation: {
    title: "Orientation - Watch before you begin!",
    url: "https://www.youtube.com/watch?v=LW586Q0DWMs",
  },
  sectorOutlook: {
    title: "Sector Outlook",
    url: "https://www.youtube.com/watch?v=DaMRxDvSRII",
  },
  todaysForecastMarketOutlook: {
    title: "Today's Forecast and Market Outlook",
    url: "https://www.youtube.com/watch?v=lK_UD2-R1hE",
  },
};

export const LV1_HEALTH_FACTORS_ABBR: { [key in Lv1HealthFactor]: string } = {
  Competition: "COM",
  ESG: "ESG",
  "Earnings Power": "EAR",
  Management: "MAN",
  Reputation: "REP",
  Systemic: "SYS",
};

export const LV1_HEALTH_FACTORS: Lv1HealthFactor[] = [
  Lv1HealthFactor.Competition,
  Lv1HealthFactor["Earnings Power"],
  Lv1HealthFactor.Management,
  Lv1HealthFactor.Reputation,
  Lv1HealthFactor.ESG,
  Lv1HealthFactor.Systemic,
];

export enum Sector {
  "Basic Materials" = "Basic Materials",
  "Communication Services" = "Communication Services",
  "Consumer Cyclical" = "Consumer Cyclical",
  "Consumer Defensive" = "Consumer Defensive",
  "Energy" = "Energy",
  "Financial Services" = "Financial Services",
  "Healthcare" = "Healthcare",
  "Industrials" = "Industrials",
  "Real Estate" = "Real Estate",
  "Technology" = "Technology",
  "Utilities" = "Utilities",
}

export const SECTORS = Object.values(Sector);

export const HORIZONS = [
  "1 day",
  "1 week",
  "2 week",
  "3 week",
  "4 week",
  "8 week",
  "12 week",
] as const;

export const enum ACTION_RECOMMENDATION {
  "N/A" = -1,
  "Sell -",
  "Sell +",
  "Watch -",
  "Watch +",
  "Buy -",
  "Buy +",
}

export enum BASE_ACTION_RECOMMENDATION {
  "Sell -" = 0,
  "Sell +",
  "Watch -",
  "Watch +",
  "Buy -",
  "Buy +",
}

export const EMPTY_SELECTED_PORTFOLIO = {
  Description: null,
  GroupId: null,
  Id: null,
  Name: null,
  VisibilityStatusName: null,
};

export const LEADERBOARD_ENTITIES = ["COMPANY", "SECTOR"];

export const SELECTED_PORTFOLIO = {
  Description: null,
  GroupId: null,
  Id: null,
  Name: null,
  VisibilityStatusName: null,
};

export const LITE_MOBILE_TABS = [
  // "Perfomance",
  "Health Factors",
  "Company Research",
  // "Issues",
  // "News Coverage",
  "Fundamentals",
];

export const MOBILE_SCREEN = 1024;
