import dynamic from "next/dynamic";
import { SVGProps } from "react";

type IconFactory = (props: Props) => JSX.Element;

type Props = SVGProps<SVGSVGElement>;

const wrapIcon: (Factory: IconFactory) => IconFactory = (
  Factory: IconFactory
) =>
  function wrappedIcon(props: Props) {
    return <Factory data-testid="lazy-bootstrap-icons" {...props} />;
  };

export const ReactBootstrapIcon = (
  x: keyof typeof import("react-bootstrap-icons")
) =>
  dynamic<Props>(
    import("react-bootstrap-icons").then((mod) =>
      wrapIcon(mod[x] as IconFactory)
    )
  );
