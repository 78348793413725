import dayjs from "dayjs";

import { formatTextToSlug } from "@/components/snapshot/helpers";
import { NarrativeSummary } from "@/model/api";
import { SampleNewsletter } from "@/model/general";

import { ApiBaseUrl } from "./app";

export const getArticleImageUrl = (narrative: NarrativeSummary) =>
  `${ApiBaseUrl}/edge_public/article_img/${getArticleBaseUrl(narrative)}`;

export const getTickerUrl = (ticker: string, referrer?: string) =>
  `/lite/${ticker}${referrer ? `?rf=${referrer}` : ""}`;

export const getArticleUrl = (narrative: NarrativeSummary, referrer?: string) =>
  `/article/${getArticleBaseUrl(narrative)}${
    referrer ? `?rf=${referrer}` : ""
  }`;

export const getArticleBaseUrl = (narrative: NarrativeSummary) =>
  `${narrative.Ticker}/${dayjs(narrative.Date).format("YYYY/MM/DD")}/${
    narrative.Slug ?? formatTextToSlug(narrative.Headline)
  }`;

export const SAMPLE_NEWSLETTERS: SampleNewsletter[] = [
  {
    Bear: "Nvidia's dominance in the AI applications market could be threatened with the launch of new AI chips by Advanced Micro Devices. The new chips offer improved memory and compute capabilities compared to Nvidia's H100 HGX platform, potentially leading to a loss in market share and revenue for Nvidia. Investors should be wary of the potential impact on Nvidia's financials in the coming years.",
    Body: "Advanced Micro Devices (AMD), a US-based semiconductor company, has launched two new AI chips, the Instinct MI300X and Instinct MI300A, designed for use in the AI market. The new releases offer improved memory capabilities and 30% more compute power compared to Nvidia's H100 HGX platform, which has dominated the AI applications market to date. The market for AI GPUs is projected to be worth $400bn over the next four years. AMD's shares have surged since the announcement, with experts suggesting that the company could take around 10% of the market share of rival Nvidia, whose shares increased only modestly.",
    Bull: "Nvidia has dominated the AI applications market to date and despite AMD's new releases, Nvidia's H100 HGX platform still holds significant market share. The AI GPUs market is projected to be worth $400bn over the next four years, offering Nvidia significant growth potential. Plus, with the rise in demand for AI and autonomous driving technologies, Nvidia's position as a leader in these areas could continue to drive its stock upwards.",
    Headline: "AMD releases new AI chips to challenge Nvidia's dominance.",
    Name: "NVIDIA CORPORATION",
    Ticker: "NVDA",
  },
  {
    Bear: "The bear case for investing in Amazon is its ongoing antitrust lawsuit filed by the Federal Trade Commission. The lawsuit accuses Amazon of manipulating prices, breaching consumer protection laws, and violating antitrust rules. If the lawsuit results in a guilty verdict, it may negatively impact Amazon's reputation and create legal and financial repercussions. Furthermore, if Amazon is forced to change its retail practices, it may decrease the company's profits and competitive advantage in the market.",
    Body: "Amazon has requested the dismissal of the Federal Trade Commission's antitrust lawsuit against the company, arguing that the accusations are common retail practices that benefit consumers and competition. The lawsuit alleges that Amazon is guilty of defrauding the public of one billion dollars and limiting competition in the market. Amazon has also been accused of breaching Washington state's consumer protection laws and violating antitrust rules by manipulating prices and restricting third-party sellers. Amazon contest all allegations, stating that they voluntarily offer competitive pricing, match discount offers from competitors, and ensure the best delivery for its Prime users.",
    Bull: "Despite the ongoing FTC antitrust lawsuit against Amazon, the company has denied allegations and continues to offer competitive pricing and delivery benefits for its customers, which benefits the company's growth prospects. Amazon's strategic position as an online marketplace, cloud-computing provider, and entertainment content creator, positions the company for long-term success as a leader in a growing e-commerce industry.",
    Headline: "Amazon asks federal judge to dismiss antitrust lawsuits.",
    Name: "AMAZON COM INC",
    Ticker: "AMZN",
  },
  {
    Bear: "A bear case for investing in CRISPR Therapeutics is that the company is still in its early stages of development and has not yet commercialized any products. While the recent approval of Casgevy is a positive development, there is no guarantee that the therapy will be successful in the market or generate significant revenue. Additionally, the company faces significant competition from other gene-editing companies, which could limit its market share and growth potential.",
    Body: "US FDA has granted approval to CRISPR Therapeutics and Vertex Pharmaceuticals for the first gene-editing therapy in the US, called Casgevy. The treatment is designed to treat sickle cell disease, which is an inherited blood disorder affecting around 100,000 people in the US. Casgevy uses the CRISPR-designed treatment to precisely target flaws in patients' genomes underlying the inherited disease, and it has been shown in clinical trials that it is effective for treating sickle cell disease. Vertex Pharmaceuticals will market the drug, but CRISPR Therapeutics will receive a 40% share of net profit or loss. The approval marks a transformative moment for the treatment of sickle cell disease.",
    Bull: "The US FDA approval of Casgevy marks a transformational moment for the treatment of sickle cell disease, with CRISPR Therapeutics and Vertex Pharmaceuticals receiving approval for the first gene-editing therapy in the US. This achievement is expected to boost CRISPR Therapeutics' reputation and potentially generate significant revenue with its 40% share of net profit or loss. As gene-editing becomes more mainstream, CRISPR Therapeutics could become a leading player in the field, making it a compelling investment opportunity for those interested in biotech innovation.",
    Headline:
      "CRISPR Therapeutics and Vertex Pharmaceuticals gain US FDA approval for groundbreaking treatment targeting sickle cell disease.",
    Name: "CRISPR THERAPEUTICS AG",
    Ticker: "CRSP",
  },
  {
    Bear: "A potential bear case for investing in Tesla is the price war sparked by lower selling prices in China, which puts pressure on profit margins. This trend could continue to limit profitability as more competitors enter the affordable EV market. Additionally, the current slowing EV adoption rate, particularly in the US, poses a risk to the growth prospects of Tesla and other EV manufacturers.",
    Body: "Tesla continues to lead the electric vehicle (EV) market as demand for EVs struggles to meet expected levels. Legacy automakers and start-ups like Tesla and Rivian are reworking product strategies to address slowing EV adoption, particularly in the US, where prices and charging infrastructure are major concerns. Lower selling prices due to price cuts from Tesla have sparked a price war, particularly in China. Suppliers are under pressure to cut costs to compete in the affordable EV market, with many automakers turning to suppliers for help. Despite short-term headwinds, the longer-term outlook for the EV supplier theme remains strong, and global EV production is expected to triple by 2030 to reach 33.4 million vehicles.",
    Bull: "Tesla's dominance in the EV market, ongoing demand for sustainable transportation solutions, and expansion into new markets make the company an attractive long-term investment opportunity. With global EV production expected to triple by 2030, Tesla's continued dominance in the electric vehicle market is a strong indicator of future success in this growing industry. Additionally, Tesla's competitive pricing strategy and commitment to developing its charging infrastructure are both key factors in its continued growth.",
    Headline:
      "Electric vehicle demand slows, leading to price cuts and supply stockpiling for Tesla and other automakers.",
    Name: "TESLA INC",
    Ticker: "TSLA",
  },
  {
    Bear: "Berkshire's legal dispute with Pilot Travel Centers could negatively impact the company's financial results and reputation, potentially leading to a decline in stock price. Additionally, the company's decision to decline a January trial may be viewed negatively by investors, suggesting that the company is not willing to resolve disputes in a timely manner. These factors could make Berkshire Hathaway a risky investment in the short term.",
    Body: "Berkshire Hathaway, run by Warren Buffett, is engaged in a legal dispute with truck stop operator Pilot Travel Centers over accounting and earnings manipulation allegations. The dispute arose when Berkshire Hathaway acquired an 80% stake in Pilot, with the Haslam family retaining the remaining 20%. The value of this remaining stake, to be sold to Berkshire Hathaway in 2024, is being disputed. Pilot Corp, owned by the Haslams, has accused Berkshire of manipulating financial statements to depress earnings. In return, Berkshire Hathaway has filed a lawsuit accusing Jimmy Haslam of bribery. Despite a court hearing being scheduled for next month, Berkshire Hathaway has declined the judge's offer to hold a January trial.",
    Bull: "Berkshire Hathaway, run by renowned investor Warren Buffett, is involved in a legal dispute with truck stop operator Pilot Travel Centers over accounting allegations. While this could cause some short-term volatility, Berkshire Hathaway has a proven track record of long-term success. The company is diversified across a variety of industries and has a history of acquiring undervalued companies and driving shareholder value. As such, investors may want to look past this legal issue and consider investing in this blue-chip stock.",
    Headline: "BERKSHIRE HATHAWAY INC",
    Name: "BERKSHIRE HATHAWAY INC",
    Ticker: "BRK.B",
  },
];
