interface Props {
  className?: string;
  title: string;
}

export const BarTitle = ({ title, className }: Props) => (
  <p
    className={`${className} relative tracking-wide leading-none uppercase text-sm lg:text-base pl-4 font-bold`}
  >
    <span className="bg-fi-red-secondary w-1.5 h-full md:h-[70%] absolute inset-y-0 left-0 top-0 md:top-1"></span>
    {title}
  </p>
);
