export const WBAPI = {
  FREE_SNAPSHOT_ENDPOINT: "/wbapi_public/free_snapshot",
  GENERATE_STAFF_SNAPSHOT_CREDITS: "wbapi/snapshot/generate_staff_credits",
  GET_OR_CREATE_SNAPSHOT_SHARE_KEY: "/wbapi_public/snapshot_share_key",
  GET_SNAPSHOT_BY_SHARE_KEY: "/wbapi_public/snapshot_by_share_key",
  GET_WORDPRESS_ARTICLE: "/wordpress_public/article",
  LIST_CUSTOMER_SETTINGS: "/wbapi/customer/list_settings",
  LIST_LITE_COMPANIES: "wbapi_public/lite_companies",
  LIST_WORDPRESS_ARTICLES: "/wordpress_public/list_articles",
  SAVE_CUSTOMER_SETTINGS: "/wbapi/customer/settings",
  SNAPSHOT_ENDPOINT: "/wbapi/snapshot",
  SNAPSHOT_ENDPOINT_PUBLIC: "/wbapi_public/snapshot",
  SNAPSHOT_PRODUCT_ENDPOINT: "/wbapi/snapshot/product",
  SUBSCRIBE_NEWSLETTER: "/newsletter_public/subscribe",
};
